import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
    routesToCache: string[] = ['terceros'];
    allowRetriveCache = {
        'terceros': true
      };
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        console.log('shouldDetach');
        const path = this.getPath(route);
        if (this.allowRetriveCache.hasOwnProperty(path)) {
            return true;
          }
          return false;
    }
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        console.log('store');
        this.storedRouteHandles.set(this.getPath(route), handle);
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        console.log('shouldAttach');
        const path = this.getPath(route);
        if (this.allowRetriveCache[path]) {
            return this.storedRouteHandles.has(this.getPath(route));
          }
        return false;
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        console.log('retrieve');
        return this.storedRouteHandles.get(this.getPath(route));
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        console.log('shouldReuseRoute', future, curr);
        if (this.getPath(future) === 'terceros' && this.getPath(curr) === 'terceros/detalle/:codigo_tercero') {
            this.allowRetriveCache['terceros'] = true;
          } else {
            this.allowRetriveCache['terceros'] = false;
        }
        return future.routeConfig === curr.routeConfig;
    }
    private getPath(route: ActivatedRouteSnapshot): string {
        if (route.routeConfig !== null && route.routeConfig.path !== null) {
          return route.routeConfig.path;
        }
        return '';
      }
}
