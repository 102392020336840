/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { IpcRenderer } from 'electron';

@Injectable({
  providedIn: 'root'
})
export class ManejadorDatosService {
  private _ipc: IpcRenderer | undefined = void 0;

  constructor() {
    if (window.require) {
      try {
        this._ipc = window.require('electron').ipcRenderer;
      } catch (e) {
        throw e;
      }
    } else {
      console.warn('Electron\'s IPC was not loaded');
    }
  }

  public on(channel: string, listener): void {
    if (!this._ipc) {
      return;
    }
    this._ipc.on('asynchronous-reply', (event, arg) => {
      console.log(arg) // prints "pong"
    })
    this._ipc.on(channel, listener);
  }

  public send(channel: string, ...args): void {
    if (!this._ipc) {
      return;
    }
    this._ipc.send(channel, ...args);
    this._ipc.send('asynchronous-message', 'ping')
  }

  public getSystemInformation() {
    return new Promise<any>((resolve, reject) => {
      if (!this._ipc) {
        console.log('No estamos en electron');
        return;
      }

      this._ipc.on('getSystemInformation-reply', (event, arg) => {
        console.log(arg);
        const systemInformation = {
          cpu: arg.cpus[0].model,
          hostname: arg.hostname,
          networkInterface: arg.networkInterfaces,
          platform: arg.platform,
          release: arg.release,
          so_version: arg.so_version,
          ram: arg.ram
        }
        console.log(systemInformation);
        resolve(systemInformation);
      })

      this._ipc.send('getSystemInformation-message', 'getSystemInformation')
    });




  }
}
