import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(private config: PrimeNGConfig) {}
  ngOnInit() {
    this.config.setTranslation({
        accept: 'Aceptar',
        reject: 'Cancelar',
        dayNames: [ 'domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado' ],
        dayNamesShort: [ 'dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb' ],
        dayNamesMin: [ 'D', 'L', 'M', 'X', 'J', 'V', 'S' ],
        // eslint-disable-next-line max-len
        monthNames: [ 'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE' ],
        monthNamesShort: [ 'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC' ],
        today: 'Hoy',
        clear: 'Borrar',
        matchAll: 'Coincidir exactamente',
        matchAny: 'Coincidir parcialmente',
        addRule: 'Añadir regla',
        removeRule: 'Eliminar regla',
        cancel: 'Cancelar',
        apply: 'Aplicar'
    });
}
}
