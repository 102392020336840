import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AdminLayoutComponent implements OnInit {

  ngOnInit() { }
}
