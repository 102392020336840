import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpXsrfTokenExtractor, HttpRequest,
  HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

/**
 * Nos permite interceptar las llamadas Http que se realizan desde la aplicación para tratar tanto el request
 * como el response.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService {
// Utilizamos el Injector para inyectar el servicio. No lo podemos inyectar en el contructor xq da error.
  constructor(private authenticacionService: AuthenticationService,
    private router: Router,
    private httpTokenExtractor: HttpXsrfTokenExtractor,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Añadimos el token de autentificación si es una ruta privada
      // TODO: Sölo comprobar las rutas privadas.
      if (request.url.startsWith(environment.urlBase)) {
          if (this.authenticacionService.isAuthenticated()) {
              const usuarioActivo = this.authenticacionService.usuarioActivo;
              const token = this.httpTokenExtractor.getToken();
              request = request.clone({
                  setHeaders: {
                      Authorization: `Bearer ${usuarioActivo.access_token}`
                  }
              });
          }
      }

      return next.handle(request)
                .pipe(
                  tap(
                    (event: HttpEvent<any>) => {
                      if (event instanceof HttpResponse) {
                      // Espacio para tratar la respuesta del servidor BackEnd.
                      }
                    },
                    (err: any) => {
                      if (err instanceof HttpErrorResponse) {
                          if (err.status === 401) {
                              // Redirigimos al login para que se valide.
                              this.router.navigate(['/login'], { queryParams: { returnUrl: err.url }});
                          }

                          if (err.status === 500 || err.status === 0) {
                            /* this.alertController.create({
                                header: 'Error Servidor',
                                subHeader: err.message,
                                message: err.error.message,
                                buttons: [
                                  {
                                    text: 'Aceptar',
                                    role: 'cancel'
                                  }
                                ]
                              }).then( alert => alert.present()) ;*/
                          }
                      }
                    })
                );
  }
}
