// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // urlBase: 'http://83.48.108.36:4085/api/'
  // urlBase: 'http://192.168.0.32:4081/gestion/api/v1',
   urlBase: 'https://api.vsoft.es:4087/gestion/api/v1'
  // urlBase: 'https://tuclinicavet.vsoft.es:4087/gestion/api/v1'
};
