import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ManejadorDatosService } from 'app/services/manejador-datos.service';
import {MessageService} from 'primeng/api';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  user = {
    usuario: '',
    password: '',
  };
  returnUrl = '';
  loading = false;
  systemInformation;


  constructor(private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _ipc: ManejadorDatosService,
    private messageService: MessageService
    ) {


     }

  ngOnInit(): void {

    // this.returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || '/';

  }

  login() {
   //  this.manejadorDatos.getSystemInformation();
    // console.log( this.user);
    this.loading = true;
    if (window.require){
      this._ipc.getSystemInformation()
      .then((res) => {
        // console.log(res);
        this.systemInformation = res;
        this.authenticationService.login(this.user.usuario, this.user.password,  this.systemInformation).subscribe(
          () => {
            this.router.navigate(['dashboard']);
            this.loading = false;
          },
          async (error) => {
            this.user.password = '';
            // console.log(error.error.error);
            this.loading = false;
            this.messageService.add({severity:'error', summary:'Error en las credenciales',
            detail:'El usuario o la contraseña no son correctas.'});
          });
      }).catch((e) => {
        console.log(e);
        this.authenticationService.login(this.user.usuario, this.user.password,  '').subscribe(
          () => {
            this.router.navigate(['dashboard']);
            this.loading = false;
          },
          async (error) => {
            this.user.password = '';
            console.log(error.error.error);
            this.loading = false;
            this.messageService.add({severity:'error', summary:'Error en las credenciales',
            detail:'El usuario o la contraseña no son correctas.'});
          });
      })
    } else {
      this.authenticationService.login(this.user.usuario, this.user.password,  this.systemInformation).subscribe(
        () => {
          this.router.navigate(['dashboard']);
        },
        async (error) => {
          this.user.password = '';
          console.log(error.error.error);
          this.loading = false;
          this.messageService.add({severity:'error', summary:'Error en las credenciales',
            detail:'El usuario o la contraseña no son correctas.'});
        });
    }

  }
}
