import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IToken } from '../interfaces/i-token';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(false);
  usuarioActivo: IToken;
  constructor(
    private http: HttpClient
  ) { }
  $authenticationState() {
    return this.authenticationState.asObservable();
  }


/**
 * Metodo para solicitar token de autenticación
 *! OJO: Si montamos el servidor en local comentar las cabeceras para evitar el error de cross domain.
 *
 * @param usuario Usuario del programa gestión
 * @param password Password del programa de gestión
 * @param terminal Información del sistema desde donde se inicia si es con electron.
 * @returns llamada al endpoint de la api softalvet login gestión
 */
  login(usuario: string, password: string, terminal) {

    const body = {
      usuario,
      password,
      terminal
    };
    const url = environment.urlBase + '/login';

    const httpOptionsToken = {
      headers: new HttpHeaders({
        /* eslint-disable @typescript-eslint/naming-convention*/
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, access-control-allow-headers'  }),
        /* eslint-enable @typescript-eslint/naming-convention*/
    };



    return this.http.post<IToken>(url, body).pipe(
      tap(async (payload) => {
        this.usuarioActivo = payload;
        console.log(payload, this.usuarioActivo);
        localStorage.setItem('currentUser', JSON.stringify(this.usuarioActivo));
        this.authenticationState.next(true);
      })
    );
  }
  logout() {
    localStorage.removeItem('currentUser');
    this.authenticationState.next(false);
  }
  isAuthenticated() {
    return this.authenticationState.value;
  }
}


